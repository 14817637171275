export default {
  "main": {
    "create": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Create ", _interpolate(_list(0))])},
    "edit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Edit ", _interpolate(_list(0))])},
    "show": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Show ", _interpolate(_list(0))])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "list": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " List"])},
    "new": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["New ", _interpolate(_list(0))])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "notActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Active"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
    "datanotfound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Not Found"])},
    "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
    "blank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "master": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Master"])}
  },
  "menu": {
    "academic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Academic"])},
    "nonacademic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non Academic"])},
    "finance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finance"])},
    "show": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Create ", _interpolate(_list(0))])},
    "delete": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Delete ", _interpolate(_list(0))])},
    "list": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " List"])},
    "new": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["New ", _interpolate(_list(0))])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "notActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Active"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
    "datanotfound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Not Found"])},
    "blank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "changePass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])},
    "darkmode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dark Mode"])},
    "lightmode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Light Mode"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log Out"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search..."])},
    "searchT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select option"])},
    "form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remarks"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail"])},
    "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
    "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WARNING!!"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interval"])},
    "hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hello,"])},
    "process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process"])}
  },
  "sidebar": {
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])}
  },
  "dashboard": {
    "bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank Statement"])},
    "transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction in Amatra"])}
  },
  "gradingaspectdetail": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grading Aspect Detail"])}
  },
  "penalty": {
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process Invoice per"])},
    "vacode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Code"])}
  },
  "generateva": {
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some students / candidates don't have VA number. Please complete the data before you generate the invoices."])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please download the data as your reference"])},
    "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["link download"])}
  },
  "sidebarFin": {
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "coa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COA"])},
    "finYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial Year"])},
    "tempEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Template"])},
    "incomes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incomes"])},
    "typeIncome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Income Type"])},
    "income": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Income"])},
    "genPenalty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate Penalty"])},
    "genVa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate VA"])},
    "upVa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload VA"])},
    "sendEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Broadcast Info"])},
    "journal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Journal"])},
    "genJournal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General Journal"])},
    "setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "typeJournal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Journal Type"])},
    "outbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outbox"])},
    "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent Box"])},
    "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment"])},
    "waiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiver"])},
    "vastudent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VA Student"])},
    "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report"])},
    "arsummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AR Outstanding Summary"])},
    "financialReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial Report"])},
    "paymentsummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Summary"])},
    "paymentsummaryclass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By Dept & Class"])},
    "paymentsummarytype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By Journal Type"])},
    "invoicetransaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice"])},
    "paymenttransaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment"])},
    "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
    "assignmenttype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignment Type"])},
    "instrument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrument"])},
    "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options"])},
    "optionsDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options Detail"])},
    "reviewercomposition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewer Composition"])},
    "indicator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indicator"])},
    "indicatortarget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indicator Target"])},
    "academicbatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Academic Batch"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
    "department": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Department"])},
    "semester": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semester"])},
    "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level"])},
    "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class"])},
    "classtransfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class Transfer"])},
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
    "students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student"])},
    "major": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Major"])},
    "majorSubject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Major Subject"])}
  },
  "indicatortarget": {
    "indicator_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indicator"])},
    "target_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Target Name"])},
    "target_uom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Target UOM"])},
    "consider_qty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consider Qty"])},
    "consider_quality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consider Quality"])},
    "consider_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consider Time"])},
    "quality_scoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quality Scoring"])},
    "quality_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quality Options"])},
    "time_scoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time Scoring"])},
    "combine_score_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combine Score Method"])},
    "qty_pct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qty Pct"])},
    "quality_pct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quality Pct"])},
    "time_pct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time Pct"])},
    "addindicatortarget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Indicator Target"])},
    "editindicatortarget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Indicator Target"])},
    "detailindicatortarget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indicator Target Detail"])}
  },
  "indicator": {
    "period_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
    "department_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Department"])},
    "position_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
    "instrument_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrument"])},
    "indicator_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indicator Code"])},
    "indicator_parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indicator Parent"])},
    "indicator_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indicator Description"])},
    "scoring_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scoring Option"])},
    "instrument_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrument Options"])},
    "target_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Target Option"])},
    "indicator_remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indicator Remarks"])},
    "indicator_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indicator Note"])},
    "indicator_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indicator Weight"])},
    "addindicator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Indicator"])},
    "editindicator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Indicator"])},
    "detailindicator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indicator Detail"])}
  },
  "reviewer": {
    "period_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
    "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
    "instrument_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrument"])},
    "reviewer_self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewer Self"])},
    "reviewer_colleague": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewer Colleague"])},
    "reviewer_superordinate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewer Super Ordinate"])},
    "is_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "addreviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Reviewer Composition"])},
    "editreviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Reviewer Composition"])},
    "detailreviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewer Composition Detail"])}
  },
  "instrument": {
    "instrument_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrument Code"])},
    "instrument_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrument Description"])},
    "instrument_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrument Source"])},
    "instrument_scoring_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrument Scoring Method"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])},
    "reviewer_self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewer Self"])},
    "reviewer_colleague": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewer Colleague"])},
    "reviewer_super_ordinate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewer Super Ordinate"])},
    "addInstrument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Instrument"])},
    "editInstrument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Instrument"])},
    "detailInstrument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrument Detail"])}
  },
  "regular": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject Assessment (Regular)"])},
    "exam_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluation Aspect"])},
    "exam_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignment Type"])},
    "exam_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignment Date"])},
    "basic_competencies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic Competencies"])},
    "remedial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remedial"])},
    "original_test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Original Test"])},
    "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
    "ignore_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignore Value"])},
    "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason"])},
    "studentaverage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Average"])},
    "finalresult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final Result"])},
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])},
    "scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scale"])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grade"])},
    "calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculate and Store Final Value"])},
    "automatic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculation"])},
    "kkm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KKM"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to calculate the final score automatically, all values ​​that have been previously calculated will be recalculated. continue?"])},
    "addRegular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Regular"])},
    "editRegular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Regular"])},
    "detailRegular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regular Detail"])},
    "comment1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment 1"])},
    "comment2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment 2"])}
  },
  "parallel": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject Assessment (Parallel)"])},
    "exam_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluation Aspect"])},
    "exam_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignment Type"])},
    "exam_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignment Date"])},
    "basic_competencies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic Competencies"])},
    "remedial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remedial"])},
    "original_test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Original Test"])},
    "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
    "ignore_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignore Value"])},
    "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason"])},
    "studentaverage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Average"])},
    "finalresult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final Result"])},
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])},
    "scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scale"])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grade"])},
    "calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculate and Store Final Value"])},
    "automatic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculation"])},
    "kkm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KKM"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to calculate the final score automatically, all values ​​that have been previously calculated will be recalculated. continue?"])},
    "addParallel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Parallel"])},
    "editParallel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Parallel"])},
    "detailParallel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parallel Detail"])},
    "comment1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment 1"])},
    "comment2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment 2"])}
  },
  "ledger": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Homeroom Ledger"])},
    "exam_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluation Aspect"])},
    "exam_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignment Type"])},
    "exam_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignment Date"])},
    "basic_competencies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic Competencies"])},
    "remedial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remedial"])},
    "original_test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Original Test"])},
    "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
    "ignore_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignore Value"])},
    "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason"])},
    "studentaverage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Average"])},
    "finalresult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final Result"])},
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])},
    "scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scale"])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grade"])},
    "calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculate and Store Final Value"])},
    "automatic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculation"])},
    "kkm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KKM"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to calculate the final score automatically, all values ​​that have been previously calculated will be recalculated. continue?"])},
    "addLedger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Homeroom Ledger"])},
    "editLedger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Homeroom Ledger"])},
    "detailLedger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Homeroom Ledger Detail"])},
    "comment1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Homeroom Comment 1"])},
    "comment2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Homeroom Comment 2"])},
    "attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendance"])},
    "underkkm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Under KKM"])},
    "averageduo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average Regular & Parallel"])},
    "regularrank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regular Rank"])},
    "averageinterst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average Interest"])},
    "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average"])},
    "overall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overall Rating"])},
    "sick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sickness"])},
    "permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With Permission"])},
    "nopermission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Permission"])},
    "avgskill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AVG Skill"])},
    "avgknow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AVG Knowledge"])},
    "gradeknow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grade"])},
    "avgks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AVG"])}
  },
  "extscore": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extracuricular Score"])},
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])},
    "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])},
    "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student"])},
    "extracuricular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extracuricular"])},
    "addExtscore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Extracuricular score"])},
    "editExtscore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Extracuricular score"])},
    "detailExtscore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extcuricular score Detail"])}
  },
  "options": {
    "period_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
    "department_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Department"])},
    "position_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
    "instrument_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrument"])},
    "is_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Active"])},
    "option_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name Options"])},
    "addOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Options"])},
    "editOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Options"])},
    "detailOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options Detail"])}
  },
  "optionsDetail": {
    "option_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Option"])},
    "option_detail_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name Option Detail"])},
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])},
    "sorting_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorting No"])},
    "is_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Active"])},
    "addOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Options"])},
    "editDetailOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Options"])},
    "detailOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options Detail"])}
  },
  "class": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Name"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
    "academicYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Academic Year"])},
    "regis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration Batch"])},
    "capacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capacity"])},
    "batch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batch"])},
    "homeroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Homeroom"])},
    "homeroom1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Homeroom 1"])},
    "homeroom2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Homeroom 2"])},
    "homeroom3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Homeroom 3"])},
    "isActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "isArchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archived"])},
    "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level"])},
    "major": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Major"])},
    "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remarks"])},
    "remarks1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remarks 1"])},
    "remarks2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remarks 2"])},
    "remarks3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remarks 3"])},
    "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created At"])},
    "updatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated At"])},
    "createdBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created By"])},
    "updatedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated By"])},
    "department": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Department"])},
    "editform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Class Form"])},
    "addform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class Form"])},
    "detailClass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Class"])},
    "placeholder": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Name"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Code"])},
      "academicYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Academic Year"])},
      "capacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Capacity"])},
      "homeroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Homeroom"])},
      "isActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
      "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select level"])},
      "department": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Department"])},
      "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Class"])},
      "major": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Major"])},
      "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Remarks"])},
      "remarks1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explain About this Class"])},
      "remarks2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explain About this Class"])},
      "remarks3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explain About this Class"])}
    }
  },
  "subject": {
    "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview Color"])},
    "addSubject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject Form"])},
    "HEX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Background Color"])},
    "font": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Font Color"])},
    "editSubject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Subject Form"])},
    "detailSubject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Subject Form"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject Code"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject Type"])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject Group"])},
    "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learning Method"])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject Header"])},
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treshold Score"])},
    "set_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set Color"])},
    "sorting_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorting No"])},
    "placeholder": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Code"])},
      "sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Sorting No"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Name"])},
      "department": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Department"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Type"])},
      "remarks1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explain About this Class"])},
      "remarks2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explain About this Class"])},
      "remarks3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explain About this Class"])},
      "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Group"])},
      "createdby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Created By"])},
      "updatedby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Updated By"])}
    }
  },
  "sidebarParent": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])}
  },
  "permission": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permission"])},
    "approver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approver"])},
    "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Name"])},
    "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Class"])},
    "date1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date 1"])},
    "date2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date 2"])},
    "permission_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permission No"])},
    "permission_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permission Type"])},
    "permission_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permission Reason"])},
    "transaction_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction Type"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remarks"])},
    "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
    "createdat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created At"])},
    "updatedat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated At"])},
    "createdby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created By"])},
    "updatedby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated By"])},
    "placeholder": {
      "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Student Name"])},
      "permission_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Permission Type"])},
      "permission_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Permission Reason"])},
      "transaction_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Transaction Type"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Description"])},
      "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Remarks"])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert File"])},
      "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert State "])},
      "createdby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Created By"])},
      "updatedby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Updated By"])}
    }
  },
  "transaction": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction Type"])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction Group"])},
    "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version"])},
    "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prefix"])},
    "format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format"])},
    "last_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last No"])},
    "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Width"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
    "domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domain"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "createdat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created At"])},
    "updatedat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated At"])},
    "createdby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created By"])},
    "updatedby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated By"])},
    "placeholder": {
      "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Version"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Code"])},
      "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Width"])},
      "domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Domain"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Name"])},
      "createdby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Created By"])},
      "updatedby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Updated By"])}
    },
    "notifAddSuccessGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction Group Created"])},
    "notifAddFailedGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to Create Transaction Group"])},
    "notifEditSuccessGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction Group Updated"])},
    "notifEditFailedGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction Group Not Found"])},
    "notifDeleteSuccessGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction Group Deleted"])},
    "notifDeleteNotFoundGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction Group Not Found"])},
    "notifAddSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction Type Created"])},
    "notifAddFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to Create Transaction Type"])},
    "notifEditSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction Type Updated"])},
    "notifEditFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction Type Not Found"])},
    "notifDeleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction Type Deleted"])},
    "notifDeleteNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction Type Not Found"])}
  },
  "generatepenalty": {
    "notifInvDateLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice date cannot be less than Financial Year"])},
    "notifInvDateMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice Date cannot exceed Financial Year End Date"])},
    "notifSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate Fines Successfully"])},
    "notifNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Fines Are Generated"])}
  },
  "vastudent": {
    "siswa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student"])},
    "major": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Major"])},
    "calonsiswa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidate"])},
    "nis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NIS"])},
    "nisn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NISN"])},
    "regNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration No"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "income_type_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Income Type"])},
    "category_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
    "department_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Department"])},
    "Level_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level"])},
    "class_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class"])},
    "batch_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batch"])},
    "group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
    "school_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School"])},
    "code_customer_va": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VA No"])},
    "notifDeleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VA Code Deleted."])},
    "notifDeleteNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VA Code Not Found."])},
    "notifAddSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VA Code Created"])},
    "notifAddFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to Create VA Code"])},
    "notifEditSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VA Code Updated"])},
    "notifEditFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to Update VA Code"])},
    "academic_year_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Academic Year"])},
    "generateVa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate VA Code"])}
  },
  "role": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
    "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version"])},
    "authority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authority"])},
    "createdat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created At"])},
    "updatedat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated At"])},
    "createdby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created By"])},
    "updatedby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated By"])},
    "placeholder": {
      "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Version"])},
      "authority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Authority"])},
      "createdby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Created By"])},
      "updatedby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Updated By"])}
    }
  },
  "rolegroup": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role Group"])},
    "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "createdat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created At"])},
    "updatedat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated At"])},
    "createdby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created By"])},
    "updatedby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated By"])},
    "placeholder": {
      "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Version"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Name"])},
      "createdby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Created By"])},
      "updatedby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Updated By"])}
    }
  },
  "roleuser": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role User"])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "placeholder": {
      "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Group"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Name"])}
    }
  },
  "approver": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction Approver"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
    "createdat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created At"])},
    "updatedat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated At"])},
    "createdby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created By"])},
    "updatedby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated By"])},
    "placeholder": {
      "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])}
    },
    "notifAddSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approvel Created"])},
    "notifAddFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to Create Approvel"])},
    "notifEditSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approvel Updated"])},
    "notifEditFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approvel Not Found"])},
    "notifDeleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approvel Deleted"])},
    "notifDeleteNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approvel Not Found"])}
  },
  "vaccines": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaccines"])},
    "addform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaccines Form"])},
    "editform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Vaccines Form"])},
    "detailVaccines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Vaccines"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of vaccine"])},
    "placeholder": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Description"])},
      "createdby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Created By"])},
      "updatedby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Updated By"])}
    }
  },
  "extracuricular": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extracuricular"])},
    "addform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extracuricular Form"])},
    "editform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Extracuricular Form"])},
    "detailExtracuricular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Extracuricular"])},
    "iscompulsory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Compulsory"])},
    "compulsory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compulsory"])},
    "notcompulsory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Compulsory"])},
    "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Name"])},
    "department": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Department"])},
    "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level"])},
    "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Class"])},
    "set_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set Color"])},
    "font": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Font Color"])},
    "placeholder": {
      "compulsory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Is Compulsory"])},
      "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Student Name"])},
      "department": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Department"])}
    }
  },
  "registrationbatch": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration batch"])},
    "addregisform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration batch Form"])},
    "editregisform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Registration Bath"])},
    "detailregisform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Registration Bath"])}
  },
  "resignationreason": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resignation Reason"])},
    "addform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason Form"])},
    "editform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Reason"])},
    "detailreason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Reason"])},
    "reason_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason"])}
  },
  "academicbatch": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Academic Batch"])},
    "editbatchform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Academic Batch"])},
    "addbatchform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Academic Batch Form"])},
    "detailbatchform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Academic Batch"])}
  },
  "academicyear": {
    "edityearform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Academic Year"])},
    "addyearform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Academic Year Form"])},
    "detailyearform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Academic Year"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Academic Year"])},
    "dateStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Date"])},
    "dateEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Date"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "placeholder": {
      "dateStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Start Date"])},
      "dateEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert End Date"])},
      "compulsory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Is Compulsory"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Academic Year"])}
    }
  },
  "employee": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee"])},
    "editform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Employee Form"])},
    "addform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee Form"])},
    "detailEmployee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Employee"])},
    "section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Section"])},
    "hc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Human Capital"])},
    "hr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Human Resource"])},
    "idCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id Card"])},
    "taxIdNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax Id Number"])},
    "nickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nickname"])},
    "degree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Degree"])},
    "religion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Religion"])},
    "ethnic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ethnic"])},
    "ein": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EIN"])},
    "idcard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Card"])},
    "familyCardId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Family Card"])},
    "passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passport"])},
    "tin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TIN"])},
    "bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank"])},
    "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website"])},
    "bankAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank Account"])},
    "healthcare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Health Care"])},
    "socialSecurity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social Security"])},
    "clinic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clinic"])},
    "department": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Department"])},
    "workUnit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work Unit"])},
    "nrp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NRP"])},
    "nuptk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUPTK"])},
    "initialDegree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initial Degree"])},
    "finalDegree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final Degree"])},
    "noIdentity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Identity"])},
    "remarksNonActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remarks Non Active"])},
    "retired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retired"])},
    "doAudit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do Audit"])},
    "totalrelated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Related"])},
    "softskill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soft Skill"])},
    "bloodType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blood Type"])},
    "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
    "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
    "noMarried": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Married"])},
    "haveMarried": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Married"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
    "salary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salary"])},
    "married": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Married"])},
    "noid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Identity"])},
    "phoneNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
    "mobileNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Number"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook"])},
    "twitter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twitter"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo"])},
    "startWorking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Working"])},
    "startYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Year"])},
    "endYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Year"])},
    "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training"])},
    "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End"])},
    "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School"])},
    "graduate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graduate"])},
    "retirement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirement"])},
    "placeOfBirth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place Of Birth"])},
    "dateOfBirth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Of Birth"])},
    "employeePin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee PIN"])},
    "placeholder": {
      "initialDegree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Initial Degree"])},
      "finalDegree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Final Degree"])},
      "section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Section"])},
      "nickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Nickname"])},
      "degree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Degree"])},
      "religion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Religion"])},
      "ethnic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Ethnic"])},
      "ein": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert EIN"])},
      "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Gender"])},
      "married": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Married"])},
      "noid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert No Identity"])},
      "phoneNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Phone Number"])},
      "mobileNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Mobile Number"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Email"])},
      "photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Photo"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Address"])},
      "placeOfBirth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Place Of Birth"])},
      "dateOfBirth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Date Of Birth"])},
      "compulsory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Is Compulsory"])}
    }
  },
  "department": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Department"])},
    "manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager"])},
    "sorting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorting"])},
    "editform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Department Form"])},
    "detailDepartment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Department"])},
    "addform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Department Form"])},
    "placeholder": {
      "manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Manager"])},
      "sorting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Sorting"])}
    }
  },
  "permissionreason": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permission Reason"])},
    "reasonDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason Detail"])},
    "editform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Reason"])},
    "addform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Reason"])}
  },
  "level": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level"])},
    "editform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Level Form"])},
    "addform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level Form"])},
    "detailLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Level"])}
  },
  "semester": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semester"])},
    "editform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Semester Form"])},
    "addform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semester Form"])},
    "detailSemester": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Semester"])}
  },
  "studytime": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Study Time"])},
    "hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hour"])},
    "isRecess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Recess?"])}
  },
  "eskulschedule": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extracuricular Schedule"])},
    "hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hour"])},
    "isRecess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Recess?"])}
  },
  "schoolidentity": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School Identity"])},
    "addform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School Identify Form"])},
    "editform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit School Identify Form"])},
    "detailSchool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail School Identify"])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name School"])},
    "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo"])},
    "address1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address 1"])},
    "address2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address 2"])},
    "address3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address 3"])},
    "address4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address 4"])},
    "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "telp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telp 1"])},
    "telp2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telp 2"])},
    "telp3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telp 3"])},
    "telp4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telp 4"])},
    "fax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fax 1"])},
    "fax2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fax 2"])},
    "fileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File Name"])},
    "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Library"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "placeholder": {
      "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Website URL"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Email"])},
      "address1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Address1"])},
      "address2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Address2"])},
      "address3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Address3"])},
      "address4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Address4"])},
      "telp1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Telp1"])},
      "telp2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Telp2"])},
      "telp3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Telp3"])},
      "telp4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Telp4"])},
      "fax1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Fax1"])},
      "fax2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Fax2"])},
      "fileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert File Name"])},
      "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Library"])}
    }
  },
  "comment": {
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
    "department": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Department"])},
    "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level"])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grade"])},
    "academic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Academic Year"])},
    "aspect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aspect"])},
    "grade_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grade"])},
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
    "gradecom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grade & Comment"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment 1"])},
    "comment_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment 2"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
    "addComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Comment"])},
    "editComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Comment"])},
    "detailComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment Detail"])}
  },
  "major": {
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Major"])},
    "department_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Department"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remarks"])},
    "min_major_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min Major Subject"])},
    "min_cross_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min Cross Subject"])},
    "addMajor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Major"])},
    "editMajor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Major"])},
    "detailMajor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Major Detail"])},
    "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created At"])},
    "createdBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created By"])},
    "updatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated At"])},
    "updatedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated By"])},
    "placeholder": {
      "department": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Department"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Description"])},
      "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Remarks"])},
      "min_major_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Min Major Subject"])},
      "min_cross_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Min Cross Subject"])}
    }
  },
  "majordate": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event"])},
    "batch_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batch"])},
    "department_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Department"])},
    "level_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level"])},
    "timeout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeout"])},
    "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Date"])},
    "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Date"])},
    "addMajorDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Major Date"])},
    "editMajorDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Major Date"])},
    "detailMajorDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Major Date Detail"])},
    "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created At"])},
    "createdBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created By"])},
    "updatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated At"])},
    "updatedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated By"])},
    "placeholder": {
      "department": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Department"])},
      "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Level"])},
      "batch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Batch"])},
      "timeout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Timeout"])}
    }
  },
  "majorSubject": {
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Major Subject"])},
    "major_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Major"])},
    "level_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level"])},
    "parallel_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parallel Subject"])},
    "addMajorSubject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Major Subject"])},
    "editMajorSubject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Major Subject"])},
    "detailMajorSubject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Major Subject Detail"])},
    "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created At"])},
    "createdBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created By"])},
    "updatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated At"])},
    "updatedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated By"])},
    "placeholder": {
      "major": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Major"])},
      "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Level"])},
      "parallel_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Parallel Subject"])}
    }
  },
  "majorLevel": {
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Major Level"])},
    "department": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Department"])},
    "major": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Major"])},
    "academic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Academic Year"])},
    "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level"])},
    "web_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web Info"])},
    "min_major": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min Major"])},
    "min_cross": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min Cross"])},
    "addMajorLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Major Level"])},
    "editMajor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Major Level"])},
    "detailMajor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Major Level Detail"])},
    "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created At"])},
    "createdBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created By"])},
    "updatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated At"])},
    "updatedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated By"])},
    "placeholder": {
      "department": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Department"])},
      "major": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Major"])},
      "academic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Academic Year"])},
      "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Level"])},
      "web_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Web Info"])},
      "min_major": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Min Major "])},
      "min_cross": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Min Cross "])}
    }
  },
  "newbatchregis": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Batch Registration"])},
    "batchId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batch Registration"])},
    "batchPrefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batch Prefix"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "placeholder": {
      "batchId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Procces"])},
      "batchPrefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Prefix"])}
    }
  },
  "candidatesgroup": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidates Group"])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
    "filled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filled"])},
    "placeholder": {
      "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Group"])}
    }
  },
  "candidate": {
    "candidateplacement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidates Placement"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidate"])},
    "test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test"])},
    "formNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form No"])},
    "nisn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NISN"])},
    "nis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NIS"])},
    "familycardId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Family Card ID"])},
    "familycard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Family Card"])},
    "noun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UN Number"])},
    "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationality"])},
    "childOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Child Order"])},
    "noOfSiblings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Of Siblings"])},
    "childStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Child Status"])},
    "ortuStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parents Status"])},
    "jkandung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siblings"])},
    "jtiri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step Brothers / Step Sisters"])},
    "condition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condition"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
    "postalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal Code"])},
    "distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])},
    "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Height"])},
    "blood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blood Type"])},
    "health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medical History"])},
    "formerSchool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Former School"])},
    "diplomaNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diploma No"])},
    "diplomaDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diploma Date"])},
    "education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education"])},
    "profession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profession"])},
    "income": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Income Type"])},
    "waNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WhatsApp Number"])},
    "guardianName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardian Name"])},
    "kkNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No KK"])},
    "correspondenceAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondence Address"])},
    "vaNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VA No"])},
    "hobby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hobbies"])},
    "lineId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line ID"])},
    "pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PIN"])},
    "submenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submenu"])},
    "summary1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum#1"])},
    "summary2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum#2"])},
    "scoretest1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score#1"])},
    "scoretest2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score#2"])},
    "scoretest3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score#3"])},
    "scoretest4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score#4"])},
    "scoretest5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score#5"])},
    "scoretest6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score#6"])},
    "scoretest7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score#7"])},
    "scoretest8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score#8"])},
    "scoretest9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score#9"])},
    "scoretest10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score#10"])},
    "placeholder": {
      "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Group"])},
      "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
      "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
      "nisn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert NISN"])},
      "nis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert NIS"])},
      "familycardId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Family Card Id"])},
      "noun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert UN Number"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Name"])},
      "nickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Nickname"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Status"])},
      "condition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Condition"])},
      "childOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Child Order"])},
      "childStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Child Status"])},
      "ortuStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Parents Status"])},
      "jkandung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Siblings"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Language"])},
      "jtiri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Stepbrother/Stepsister"])},
      "postalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Postal Code"])},
      "formerSchool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Former School"])},
      "diplomaNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Diploma No"])},
      "diplomaDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Diploma Date"])},
      "distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Distance"])},
      "education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Education"])},
      "profession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Profession"])},
      "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Weight"])},
      "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Height"])},
      "health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Medical History"])},
      "income": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Income"])},
      "vaNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert VA No"])},
      "hobby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Hobby"])},
      "kkNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert No KK"])},
      "correspondenceAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Correspondence Address"])},
      "lineId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Line ID"])},
      "noOfSiblings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert No Of Siblings"])},
      "summary1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Sum#1"])},
      "summary2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Sum#2"])},
      "scoretest1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Score#1"])},
      "scoretest2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Score#2"])},
      "scoretest3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Score#3"])},
      "scoretest4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Score#4"])},
      "scoretest5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Score#5"])},
      "scoretest6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Score#6"])},
      "scoretest7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Score#7"])},
      "scoretest8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Score#8"])},
      "scoretest9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Score#9"])},
      "scoretest10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Score#10"])}
    }
  },
  "students": {
    "father_username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Father`s Username"])},
    "mother_username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mother`s Username"])},
    "father_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Father`s Password"])},
    "mother_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mother`s Password"])},
    "student_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student`s Password"])},
    "pwChange_father": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Father`s PWChange Time"])},
    "pwChange_mother": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mother`s PWChange Time"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students"])},
    "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Name"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student`s Email"])},
    "parents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parents"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail"])},
    "father": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Father"])},
    "mother": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mother"])},
    "father_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Father`s Email"])},
    "mother_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mother`s Email"])},
    "personalData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Data"])},
    "hero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students Data"])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
    "filled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filled"])},
    "cardNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Card Number"])},
    "placeholder": {
      "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Group"])},
      "cardNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert ID Card Number"])}
    },
    "majorName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Major Description"])},
    "siswaorcalon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students or Candidate"])}
  },
  "teacher": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
    "subtitute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Substitute Teacher"])},
    "teacherrole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher Role"])},
    "teacherextracuricular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extracurricular Teacher"])},
    "placeholder": {
      "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Role"])}
    }
  },
  "graduate": {
    "promotionGrade": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grade Promotion"])},
      "hero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grade Promotion Data"])},
      "classpurpose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To Class"])}
    },
    "toClass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To Class"])}
  },
  "gradingaspects": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assessment Aspects"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assessment Aspect Detail"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
    "form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final Score Rule Form"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final Score Rule Edit"])},
    "abbreviation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbreviation"])},
    "assignmentType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignment Type"])},
    "criteria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criteria"])},
    "gradingrule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grading Rule"])},
    "nmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passing Grade"])},
    "nmax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximal Score"])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grading"])},
    "finalscorerule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final Score Rule"])},
    "kkm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passing Grade"])},
    "portion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portion"])},
    "placeholder": {
      "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Role"])}
    }
  },
  "gradingrule": {
    "form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grading Rule Form"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grading Rule Edit"])}
  },
  "finalscorerule": {
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "asstype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignment Type"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight ( % )"])},
    "aspect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aspects"])},
    "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level"])},
    "asweight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignment & Weight"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])}
  },
  "assignmentType": {
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sequence"])}
  },
  "siblings": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Siblings"])},
    "isregis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Registered as a student?"])},
    "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
    "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School"])},
    "placeholder": {
      "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert School"])}
    }
  },
  "syllabus": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syllabus"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy Syllabus"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syllabus Detail"])},
    "detailNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syllabus Detail No."])},
    "kd1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Topics Curriculum1"])},
    "kd2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Topics Curriculum2"])},
    "indicator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indicator"])},
    "warningSemester": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not allowed to copy in the same semester"])},
    "firstInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on the Search button above to display Syllabus"])},
    "secondInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click one of the Core Competencies on the left to see the basic competencies"])}
  },
  "timeplan": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teaching Timeplan"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syllabus Detail"])},
    "detailNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syllabus Detail No."])},
    "cambridge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syllabus Cambridge"])},
    "indicator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indicator"])},
    "topics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Topics"])},
    "session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session"])},
    "warningSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Session Does not Match"])},
    "firstInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on the Search button above to display Teaching Timeplan Data"])}
  },
  "lessonplan": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesson Plan"])},
    "session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesson Plan Session"])},
    "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesson Plan Source"])},
    "sourcee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source"])},
    "topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Topic"])},
    "subTopic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub Topic"])},
    "totalProsem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teaching Timpelan Total Session"])},
    "totalRpp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesson Plan Total Session"])},
    "totalMeeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Meeting"])},
    "teachingMaterial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teaching Materials"])},
    "meeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meeting"])},
    "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Method"])},
    "sessionn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session"])},
    "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time Allocated"])},
    "objectives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objectives"])},
    "mainlesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main Lessons"])},
    "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])},
    "opening": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opening"])},
    "mainActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main Activity"])},
    "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closing"])},
    "firstInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on the Search button above to display Lesson Plan"])},
    "secondInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click one of the Lesson Plans on the left"])},
    "assignmentDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignment Description"])}
  },
  "teacherjournal": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher Journal"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Activity"])},
    "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Activity"])},
    "infal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infal"])},
    "startTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Time"])},
    "endTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Time"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
    "firstHour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Hour"])},
    "lastHour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Hour"])}
  },
  "coa": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COA"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
    "nameForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COA Name"])},
    "addName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input COA..."])},
    "addRemarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input remarks..."])},
    "addCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input code..."])},
    "addCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input category..."])},
    "notifAddSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COA Created"])},
    "notifAddFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to Create COA"])},
    "notifEditSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COA Updated"])},
    "notifEditFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COA Not Found"])},
    "notifDeleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COA Deleted"])},
    "notifDeleteFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data cannot be deleted because this data is already connected"])},
    "notifDeleteNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COA Not Found"])}
  },
  "financialyear": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial Year"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
    "addDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input description..."])},
    "addFinyear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input financial year..."])},
    "addInvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input invoice prefix..."])},
    "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice Prefix"])},
    "notifAddSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial Year Created"])},
    "notifAddFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to Create Financial Year"])},
    "notifEditSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial Year Updated"])},
    "notifEditFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial Year Not Found"])},
    "notifDeleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial Year Deleted"])},
    "notifDeleteFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data cannot be deleted because this data is already connected to the journal"])},
    "notifDeleteNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial Year Not Found"])}
  },
  "settingemail": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Template Alert"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
    "notifSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setting Email Updated"])},
    "notifFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setting Email Not Found"])}
  },
  "vacode": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VA Code"])},
    "vacompanycode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VA Company Code"])},
    "vasubcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VA Sub Company Code"])},
    "bankName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank"])},
    "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Method"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
    "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Length"])},
    "notifAddSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VA Created"])},
    "notifAddFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to Create VA"])},
    "notifEditSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VA Code Updated"])},
    "notifEditFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VA Code Not Found"])},
    "notifDeleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VA Deleted"])},
    "notifDeleteFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VA Not Found"])}
  },
  "settingodoo": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setting Odoo"])},
    "dbname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Database Name"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
    "companyname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Name"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
    "syncschedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sync Schedule"])},
    "notifSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setting Odoo Updated"])},
    "notifFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setting Odoo Not Found"])}
  },
  "settingspeed": {
    "red": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Red"])},
    "yellow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yellow"])},
    "green": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Green"])},
    "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default"])},
    "ledger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ledger"])}
  },
  "settingschool": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inovice Setting"])},
    "schoolname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School Name"])},
    "taxno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax No"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo"])},
    "notifSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inovice Setting Updated"])},
    "notifFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inovice Setting Not Found"])}
  },
  "templateannouncement": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template Announcement"])},
    "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event"])},
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Subject"])},
    "opening": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Opening"])},
    "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Body"])},
    "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Closing"])},
    "web": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web Announcement"])},
    "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Days to Remind"])},
    "createdat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created at"])},
    "updatedat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated at"])},
    "createdby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created By"])},
    "updatedby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated By"])},
    "glossary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glossary"])}
  },
  "templateemail": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Template"])},
    "tob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of Bill"])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Header"])},
    "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Body"])},
    "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Footer"])},
    "interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Interval"])},
    "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Email From"])},
    "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Days"])},
    "days_ago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["days ago"])},
    "daybefore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Days Before Due Date"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Time"])},
    "typeBill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of Bill"])},
    "bill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment"])},
    "addcc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input email address..."])},
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
    "addSubject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input subject..."])},
    "addHead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Field"])},
    "addBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Field"])},
    "addFooter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Field"])},
    "addBill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select type of bill"])},
    "addDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Email From"])},
    "addInterval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Interval"])},
    "addTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select delivery time"])},
    "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hint: Write the email, separate with (;) without spaces if the recipient is more than 1 (one)!"])},
    "notifAddSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Template Created"])},
    "notifAddFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to Create Email Template"])},
    "notifEditSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Template Updated"])},
    "notifEditFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Template Not Found"])},
    "notifDeleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Template Deleted"])},
    "notifDeleteNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Template Not Found"])}
  },
  "typesofincome": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Types of Income"])},
    "accountCodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Codes"])},
    "priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priority"])},
    "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice Prefix"])},
    "recurring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recurring Transactions"])},
    "fine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penalty"])},
    "ispct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Percent"])},
    "installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installment"])},
    "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
    "times": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Times"])},
    "interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interval"])},
    "maxTransInstal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max  Installment Tenor"])},
    "maxTrans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max Transaction"])},
    "maxFine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max Penalty Amount"])},
    "cash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash"])},
    "income": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Income"])},
    "receivable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receivable"])},
    "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount"])},
    "prepaidincome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prepaid Income"])},
    "paymentAutomation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automation"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy Type of Income"])},
    "trgroup_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction Group Payment"])},
    "trgroup_waiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction Group Waiver"])},
    "notifDeleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of Income Deleted."])},
    "notifDeleteNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of Income Not Found."])},
    "notifDeleteFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data cannot be deleted because it has been used."])},
    "notifCopySuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Types of Income Copied"])},
    "notifCopyFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to Copy Types of Income"])},
    "notifAddSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of Income Created"])},
    "notifAddFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to Create Type of Income"])},
    "notifEditSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of Income Updated"])},
    "notifEditFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of Income Not Found"])}
  },
  "sendemail": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Broadcast Info"])},
    "process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly Billing Process"])},
    "bill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bill"])},
    "schoolyear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School Year"])},
    "invoiceemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail Invoice"])},
    "receiptemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail Receipt"])},
    "publishinvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish Invoice"])},
    "publishpayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish Payment"])},
    "publishemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish"])},
    "unpublishedemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unpublish"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "notifSendSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Email Created"])},
    "notifSendNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This child's father or mother's email does not exist"])},
    "notifSendFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please activate the appropriate email template"])},
    "notifSendSelectBill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select students or Candidate students"])},
    "notifSendSelectDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select date"])},
    "notifPublishSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Published Success"])},
    "notifPublishFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Published Failed"])},
    "notifUnpublishSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unpublished Success"])},
    "notifUnpublishFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unpublished Failed"])}
  },
  "income": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Income"])},
    "typeJournal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of Journal"])},
    "billNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice"])},
    "generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate"])},
    "transNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction No"])},
    "paymentNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment No"])},
    "waiverNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiver No"])},
    "typeIncome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of Income"])},
    "dp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Down Payment"])},
    "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value"])},
    "installmentValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installment Value"])},
    "installmentAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installment Tenor"])},
    "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount"])},
    "billDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice Date"])},
    "dueDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due Date"])},
    "penalty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penalty"])},
    "finePayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penalty Payment"])},
    "remainingPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remaining Payment"])},
    "outstanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outstanding"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
    "payDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Date"])},
    "waiverDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiver Date"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay"])},
    "payValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Value"])},
    "waiverValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiver Value"])},
    "generateva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate VA"])},
    "uploadva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload VA"])},
    "addList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to payment list"])},
    "addDept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select department"])},
    "addFinyear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select financial year"])},
    "addCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select category"])},
    "addIncome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select income"])},
    "genInv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate Invoice"])},
    "createInv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Invoice"])},
    "copyInv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy Invoice"])},
    "createPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Payment"])},
    "feewaiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiver"])},
    "merge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merged Invoice No"])},
    "btnmerge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merge"])},
    "addLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select level"])},
    "addClass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select class"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "notActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Active"])},
    "addBatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select batch"])},
    "addGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select group"])},
    "batch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batch"])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "regNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration No"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "vaNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VA No"])},
    "mobileNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile No"])},
    "telpNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone No"])},
    "invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice"])},
    "addJournal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select journal"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
    "addInvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select invoice"])},
    "inputTrans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input transaction no..."])},
    "inputValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input value..."])},
    "inputDisc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input discount..."])},
    "inputAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input installment amount..."])},
    "inputDp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input down payment..."])},
    "inputInsVal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input installment value..."])},
    "payForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Form"])},
    "feewaiverForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiver Form"])},
    "addPaay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay"])},
    "addAcc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select account"])},
    "acc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
    "bankorcash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank / Cash"])},
    "addbankorcash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Bank / Cash"])},
    "advanceincome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advance Income"])},
    "incomejournaldate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reversing Journal Date"])},
    "allinv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Invoice"])},
    "outstandingreport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outstanding Report"])},
    "pdfprotector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As Attachment"])},
    "notifPaymentSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Created"])},
    "notifPaymentSendEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Email Failed"])},
    "notifPaymentFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to Create Payment"])},
    "notifPaymentEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Updated"])},
    "notifPaymentNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Not Found"])},
    "notifPaymentDel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Deleted"])},
    "notifGenerateDateLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice date cannot be less than Financial Year"])},
    "notifGenerateInvDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice Date cannot exceed Financial Year End Date"])},
    "notifGenerateSucess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accrual Income Generated"])},
    "notifGenerateExist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice is already exist"])},
    "notifGenerateFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to Genereate Income"])},
    "notifGenerateNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No bills are growing"])},
    "notifCopySuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accrual Income Copied"])},
    "notifCopyFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to Copy Income"])},
    "notifAddValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make sure the value is filled in"])},
    "notifAddJournalDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Income Journal Date cannot cannot be less than Invoice date"])},
    "notifAddInvDateLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice date cannot be less than Financial Year"])},
    "notifAddInvDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice Date cannot exceed Financial Year End Date"])},
    "notifAddDueDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due Date cannot cannot be less than Invoice date"])},
    "notifAddTenor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenor can't be more than"])},
    "notifAddExist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice is already exist"])},
    "notifAddSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accrual Income Created"])},
    "notifAddDateLessCalon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Income Journal Date cannot cannot be less than Invoice date"])},
    "notifAddInvDateCalon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice Date must in financial year period"])},
    "notifAddDueDateCalon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due Date must in financial year period"])},
    "notifAddSuccessCalon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidate Accrual Income Created"])},
    "notifEditSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accrual Income Updated"])},
    "notifEditFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accrual Income Not Found"])},
    "notifEditSuccessCalon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidate Accrual Income Updated"])},
    "notifEditNotFoundCalon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidate Accrual Income Not Found"])},
    "notifEditFailedCalon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This invoice already has a derivative, it can't be changed!"])},
    "notifDeleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accrual Income Deleted"])},
    "notifDeleteFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accrual Income Not Found"])},
    "notifDeleteSuccessCalon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidate Accrual Income Deleted"])},
    "notifDeleteFailedCalon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidate Accrual Income Not Found"])},
    "notifJurnalDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reversing journal date cannot be earlier than invoice date"])},
    "notifMergeSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merged Invoice Success"])},
    "notifMergeFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merged Invoice Failed"])},
    "notifMergeSame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The VA No must be the same"])}
  },
  "payment": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment"])}
  },
  "uploadva": {
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully uploaded"])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload failed"])},
    "uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has been uploaded"])}
  },
  "setting": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setting"])},
    "allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow COA"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time SFTP"])}
  },
  "journal": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Journal"])},
    "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General Journal"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Journal Name"])},
    "noJournal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Journal No"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction Details"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "akunName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Name"])},
    "debit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debit"])},
    "credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit"])},
    "Officer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Officer"])},
    "selectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select All"])},
    "notifAddSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Journal Type Created"])},
    "notifAddFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to Create Journal Type"])},
    "notifEditSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Journal Type Updated"])},
    "notifEditFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Journal Type Not Found"])},
    "notifDeleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Journal Type Deleted"])},
    "notifDeleteNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Journal Type Not Found"])},
    "endlessStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Date cannot be less than Start Date"])},
    "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check Odoo Success"])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check Odoo Failed"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "nothing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "semua": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
    "transaksi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "no_jurnal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jurnal No"])},
    "belum_sync": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Sync"])},
    "sudah_syns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syns Already"])},
    "belum_validasi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Validated"])},
    "syncdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sync Date"])},
    "reversingdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reversing Date"])},
    "invoiceno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice No"])},
    "invoicedate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice Date"])},
    "reversingjurnaldate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reversing Journal Date"])},
    "reversingerp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reversing ERP"])}
  },
  "general": {
    "notifAddSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General Created"])},
    "notifAddFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to Create General"])},
    "notifEditSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General Setting Updated"])},
    "notifEditFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General Setting Not Found"])}
  },
  "va": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VA"])},
    "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
    "treasury": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treasury"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Date"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Time"])},
    "fileDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File Date"])},
    "uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploaded at"])},
    "paymentdetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Detail"])}
  },
  "schedule": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schedule"])}
  },
  "presence": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendance"])},
    "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default"])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendance List"])},
    "in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IN"])},
    "out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OUT"])},
    "attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendance"])},
    "present": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Present"])},
    "sick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sick"])},
    "permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permission"])},
    "absent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absent"])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month"])},
    "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
    "tardy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tardy"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on the Process button above to input Student Attendance"])},
    "inforeport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on the Process button above to show the report"])},
    "listInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on the Process button above to display Attendance List"])}
  },
  "presenceParent": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendance"])},
    "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default"])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendance list"])},
    "in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In"])},
    "out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Out"])},
    "attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendance"])},
    "present": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Present"])},
    "sick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sick"])},
    "permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permission"])},
    "absent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absent"])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month"])},
    "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
    "tardy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tardy"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on the Process button above to input Student AttendanceParent"])},
    "inforeport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on the Process button above to show the report"])},
    "listInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on the Process button above to display AttendanceParent List"])},
    "childName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Child Name"])},
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
    "addChildName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change child name"])},
    "addSubject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change subject"])},
    "addMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change month"])},
    "greetings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hello,"])},
    "alertFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the child's name, month, and subject on the filter menu above!"])},
    "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remarks"])},
    "jan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["January"])},
    "feb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["February"])},
    "mar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["March"])},
    "apr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April"])},
    "mei": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["May"])},
    "jun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["June"])},
    "jul": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["July"])},
    "agu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["August"])},
    "sep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September"])},
    "okt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["October"])},
    "nov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November"])},
    "des": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["December"])},
    "day1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monday"])},
    "day2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuesday"])},
    "day3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wednesday"])},
    "day4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thursday"])},
    "day5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friday"])},
    "day6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saturday"])},
    "day7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunday"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome"])}
  },
  "day": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day"])},
    "sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunday"])},
    "monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monday"])},
    "tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuesday"])},
    "wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wednesday"])},
    "thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thursday"])},
    "friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friday"])},
    "saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saturday"])}
  },
  "confirmDelete": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Delete"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure to delete this data?"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure to Reset this Password?"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to resend this data?"])}
  },
  "confirmPublish": {
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to publish this data?"])},
    "messageun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to unpublish this data?"])}
  },
  "candidateDetail": {
    "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidate Student Personal Data"])}
  },
  "schooltime": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School Time"])}
  },
  "scheduletime": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schedule"])},
    "editform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Schedule"])},
    "addform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Schedule"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Schedule"])}
  },
  "workunit": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work Unit"])},
    "sortingNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorting No"])},
    "calOvertime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculate Overtime"])}
  },
  "workinghour": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Working Hours"])},
    "sortingNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorting No"])},
    "memberperiodically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member Periodically"])},
    "calOvertime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculate Overtime"])}
  },
  "shift": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shift"])}
  },
  "position": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])}
  },
  "certification": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certification"])},
    "institution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institution"])},
    "occurUntil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Occur Until"])}
  },
  "family": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Family"])},
    "relation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relation"])},
    "isEmployee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Employee?"])},
    "isStudent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Student?"])}
  },
  "leave": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave"])},
    "requesrt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request"])},
    "reqLeave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Leave"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximal"])},
    "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit"])},
    "salaryDeduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salary Deduction"])},
    "atleastWork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least work for"])}
  },
  "users": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
    "reqLeave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Leave"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximal"])},
    "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit"])},
    "salaryDeduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salary Deduction"])},
    "atleastWork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least work for"])}
  },
  "outstanding": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outstanding"])},
    "ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AR Outstanding"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Type"])},
    "cutoff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cut Off Date"])},
    "receivableage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receivable Age (Day)"])},
    "tuitionfee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuition fee"])}
  },
  "araging": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AR Aging"])}
  },
  "outbox": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outbox"])},
    "detil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Outbox"])},
    "user_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel"])},
    "receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receiver"])},
    "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sender"])},
    "send_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Date"])},
    "send_scheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Scheduled"])},
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
    "bcc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BCC"])},
    "cc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CC"])},
    "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retry"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
    "notifAddSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend Success"])},
    "notifAddFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend Failed"])}
  },
  "sent": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent Box"])},
    "detil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Sent Box"])},
    "user_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel"])},
    "receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receiver"])},
    "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sender"])},
    "send_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Date"])},
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
    "bcc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BCC"])},
    "cc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CC"])},
    "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retry"])}
  },
  "batas ulang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["------------------------------------------------------------"])},
  "finance": {
    "button": {
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
      "btnAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
      "btnConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
      "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
    },
    "sidebar": {
      "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
      "master": {
        "master": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Master"])},
        "finYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial Year"])},
        "coa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COA"])},
        "emailTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Template"])},
        "journalType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Journal Type"])},
        "vaCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VA Code"])},
        "vaStudent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VA Student"])},
        "transaction": {
          "transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction"])},
          "transType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction Type"])},
          "transGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction Group"])}
        }
      },
      "incomes": {
        "incomes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incomes"])},
        "incType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Income Type"])},
        "income": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Income"])},
        "uploadVa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload VA"])},
        "arOutstanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AR Outstanding"])},
        "arAging": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AR Aging"])},
        "genPenalty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate Penalty"])},
        "upIncome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Income"])},
        "genVa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate VA"])},
        "sendEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Email"])},
        "invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice"])},
        "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment"])},
        "boardInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boardcast Info"])}
      },
      "journal": {
        "journal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Journal"])},
        "importjournalentry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import Journal Entry"])},
        "generalJournal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General Journal"])},
        "reversingdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reversing Journal"])}
      },
      "email": {
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "sentBox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent Box"])},
        "outBox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Out Box"])}
      },
      "settings": {
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
        "setOdoo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setting Odoo"])},
        "setEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setting Email"])},
        "setSchool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inovice Setting"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General Setting"])},
        "speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chart & Analysis"])},
        "module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On Module"])}
      },
      "report": {
        "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report"])},
        "outArSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AR Outstanding Summary"])},
        "financialReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial Report"])},
        "paySummart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Summary"])},
        "byDeptClass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By Dept & Class"])},
        "byJournal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By Journal Type"])}
      }
    },
    "financialYear": {
      "addFinYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Financial Year"])},
      "editFinYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Financial Year"])},
      "detailFinYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial Year Detail"])},
      "add": {
        "department": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Department"])},
        "plcDept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input department"])},
        "invPrefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice Prefix"])},
        "plcInvPrefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input invoice prefix"])},
        "plcFinYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input financial year"])},
        "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Date"])},
        "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Date"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
        "plcDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input description"])}
      }
    },
    "coa": {
      "addCoa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add COA"])},
      "editCoa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit COA"])},
      "detailCoa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COA Detail"])},
      "add": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
        "plcCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input category"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
        "plcCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input code"])},
        "coaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COA Name"])},
        "plcCoaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input COA name"])},
        "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remarks"])},
        "plcRemarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input remarks"])}
      }
    },
    "tempEmail": {
      "addTemp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Template"])},
      "editTemp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Template"])},
      "detailTemp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template Detail"])},
      "add": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
        "plcCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input category"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
        "plcCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input code"])},
        "coaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COA Name"])},
        "plcCoaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input COA name"])},
        "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remarks"])},
        "plcRemarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input remarks"])}
      }
    },
    "typeJournal": {
      "addJournal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Journal"])},
      "editJournal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Journal"])},
      "detailJournal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Journal Detail"])},
      "add": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])}
      }
    },
    "va": {
      "addVa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add VA"])},
      "editVa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit VA"])},
      "detailVa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VA Detail"])},
      "add": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])}
      }
    },
    "vaCode": {
      "addVaCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add VA Code"])},
      "editVaCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit VA Code"])},
      "detailVaCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VA Code Detail"])},
      "selectCoa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select COA"])},
      "add": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])}
      }
    },
    "vaStudent": {
      "addVaStudent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add VA Student"])},
      "addVaCandidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add VA Candidate"])},
      "editVaStudent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit VA Student"])},
      "editVaCandidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit VA Candidate"])},
      "detailVaStudent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VA Student Detail"])},
      "detailVaCandidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VA Candidate Detail"])},
      "add": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])}
      },
      "generateVa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VA Generate"])}
    },
    "transGroup": {
      "addTransGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Transaction Group"])},
      "editTransGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Transaction Group"])},
      "detailTransGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction Group Detail"])},
      "add": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])}
      }
    },
    "transType": {
      "addTransType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Transaction Type"])},
      "editTransType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Transaction Type"])},
      "detailTransType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction Type Detail"])},
      "add": {
        "addRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Role"])}
      }
    },
    "typeIncome": {
      "addTypeInc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Types of Income"])},
      "editTypeInc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Types of Income"])},
      "datailTypeInc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typpes of Income Detail"])},
      "copyTypeInc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy Types of Income"])},
      "add": {
        "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      }
    }
  },
  "comboardcategory": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comboard Category"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created At"])},
    "updatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated At"])},
    "createdBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created By"])},
    "updatedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated By"])},
    "placeholder": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Description"])},
      "createdby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Created By"])},
      "updatedby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Updated By"])}
    },
    "comboarduser": {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comboard User"])},
      "isActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
      "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created At"])},
      "createdBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created By"])},
      "updatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated At"])},
      "updatedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated By"])}
    },
    "comboardrecipients": {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comboard Recipients"])},
      "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created At"])},
      "createdBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created By"])},
      "updatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated At"])},
      "updatedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated By"])},
      "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitation"])}
    }
  },
  "comboard": {
    "editcomboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Comboard Form"])},
    "addcomboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comboard Form"])},
    "detailcomboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Comboard"])},
    "titlecomboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "show_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Date"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
    "is_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Event"])},
    "start_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Event"])},
    "end_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Event"])},
    "to_parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To Parent"])},
    "to_student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To Student"])},
    "add_calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Calendar"])},
    "recalled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recalled"])},
    "recall_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recall Time"])},
    "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason"])},
    "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link URL"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comboard Header"])},
    "comboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comboard"])},
    "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created At"])},
    "updatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated At"])},
    "createdBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created By"])},
    "updatedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated By"])},
    "placeholder": {
      "comboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Comboard"])},
      "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Reason"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Title"])},
      "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Link URL Youtube"])},
      "createdby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Created By"])},
      "updatedby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Updated By"])}
    }
  },
  "attachment": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comboard Attachment"])},
    "title_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comboard Header ID"])},
    "title_file1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File 1"])},
    "comboard_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comboard ID"])},
    "attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachment"])},
    "form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachment Form"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachment Edit"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachment Detail"])},
    "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created At"])},
    "updatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated At"])},
    "createdBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created By"])},
    "updatedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated By"])},
    "placeholder": {
      "comboard_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Comboard ID"])},
      "createdby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Created By"])},
      "updatedby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Updated By"])}
    }
  },
  "hrd": {
    "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Date"])},
    "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Date"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
    "editform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Period Form"])},
    "addform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period Form"])},
    "detailperiod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Period"])},
    "hrd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
    "master": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Master"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])}
  },
  "assigntypes": {
    "assign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignment Type Score"])},
    "subject_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
    "student_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student"])},
    "nis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NIS"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
    "editform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Assignment Type Score Form"])},
    "addform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignment Type Score Form"])},
    "detailperiod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Assignment Type Score"])}
  },
  "branch": {
    "branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branch"])},
    "school_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "editbranch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Branch"])},
    "addbranch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branch Form"])},
    "detailbranch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Branch"])}
  },
  "classtransfer": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class Transfer"])},
    "master": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "nis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert NIS"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Name"])},
    "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Class"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
    "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Remarks"])}
  },
  "settingtimeout": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Major & Cross Major Selection Process"])},
    "timeout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeout"])},
    "persen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribution Rate"])}
  },
  "studentparallelsubject": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Parallel Subject"])},
    "master": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
    "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Name"])},
    "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Class"])},
    "nis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NIS"])}
  },
  "studentselectsubject": {
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "major": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Major"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Subject Selection"])},
    "master": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
    "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Name"])},
    "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Class"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Confirm Time"])},
    "nis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NIS"])}
  },
  "calendar": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendar"])},
    "master": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "activity_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity Name"])},
    "start_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Time"])},
    "end_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Time"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "department_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Department Name"])},
    "academic_year_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Academic Year Name"])},
    "post_comboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post To Comboard"])},
    "posting_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posting Time"])},
    "notif_student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notif Student"])},
    "notif_parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notif Parent"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
    "calendar_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Calendar"])},
    "calendar_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Calendar"])},
    "edit_calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Calendar"])},
    "academic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Academic Year"])},
    "copy_calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy Calendar"])},
    "list_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List Calendar Detail"])}
  },
  "schoolevent": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School Events"])},
    "master": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])}
  },
  "modul": {
    "modul": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add On "])},
    "admission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Admission"])},
    "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration"])},
    "subjectselection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject Selection"])},
    "interest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interest Club Selection"])}
  },
  "studentdoc": {
    "docName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Category"])},
    "del": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Document"])}
  },
  "holiday": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holiday"])},
    "startdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Date"])},
    "enddate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Date"])},
    "addholiday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Holiday Form"])},
    "detailholiday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Holiday Form"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holiday Description"])},
    "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School Name"])},
    "master": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "placeholder": {
      "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert School"])}
    }
  },
  "facility": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facility"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facility Name"])},
    "hr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Human Resource"])},
    "qty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
    "uom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit Of Measurement"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
    "is_hr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Human Resource"])},
    "detailholiday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Holiday Form"])},
    "addfacility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Facility Form"])},
    "editfacility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Facility Form"])},
    "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School Name"])},
    "master": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "placeholder": {
      "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Category"])},
      "uom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Scale"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Insert Facility name"])}
    }
  },
  "facilityCharge": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facility"])},
    "facility_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facility Name"])},
    "facility_condition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facility Condition"])},
    "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency"])},
    "replacement_charges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replacement Charges"])},
    "is_hr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Human Resource"])},
    "detailfacility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Facility Charge"])},
    "addfacility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Facility Form"])},
    "editfacility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Facility Form"])},
    "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School Name"])},
    "master": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "placeholder": {
      "condition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Condition"])},
      "charges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Charge"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Price List"])},
      "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert School"])}
    }
  },
  "room": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Master Room"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room Name"])},
    "User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager Room"])},
    "building_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Building Name"])},
    "branch_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branch Name"])},
    "owner_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner Name"])},
    "room_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room Name"])},
    "capacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capacity"])},
    "lattitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lattitude"])},
    "longtitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longitude"])},
    "altitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altitude"])},
    "Capacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capacity"])},
    "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])},
    "room_manager_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room Manager Email"])},
    "room_manager_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room Manager Name"])},
    "room_manager_username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room Manager Email"])},
    "replacement_charges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replacement Charges"])},
    "room_parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent Room"])},
    "sort_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort No"])},
    "detailOwnerroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Room Owner"])},
    "detailActivityroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Room Activity"])},
    "addroomActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form Add Room Activity"])},
    "editroomActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form Edit Room Activity"])},
    "addroomOperating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form Add Room Operation"])},
    "editroomOperating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form Edit Room Operation"])},
    "detailOperatingroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Room Operation"])},
    "addroomOccupation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form Add Room Occupation"])},
    "editroomOccupation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form Edit Room Occupation"])},
    "detailOccupationroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Room Occupation"])},
    "detailRoom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Room"])},
    "addroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Room Form"])},
    "edituroom  ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Room Form"])},
    "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School Name"])},
    "master": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "RoomOwners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Master Room Owner"])},
    "addroomOwner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Room Owner Form"])},
    "editroomOwner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Room Owner Form"])},
    "placeholder": {
      "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select School"])},
      "branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Branch"])},
      "building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Building"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Manager"])},
      "room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Room"])},
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Activity"])}
    }
  },
  "roomOwner": {
    "room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room Data List"])},
    "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner Room Data List"])},
    "placeholder": {
      "room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Room"])},
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Activity"])},
      "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Owner Room"])}
    }
  },
  "tenant": {
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenant Code"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenant Name"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenant Address"])},
    "PIC_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person In Charge Name"])},
    "PIC_Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person In Charge Phone Number"])},
    "PIC_Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person In Charge E-Mail"])},
    "PIC_Wa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person In Charge WhatsApp Number"])},
    "FacilityPriceList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facility Price List"])},
    "placeholder": {
      "room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Room"])},
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Activity"])},
      "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Owner Room"])}
    }
  },
  "timeOper": {
    "activity_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity Name"])},
    "activity_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity Type"])},
    "is_holiday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Holiday Availabel ?"])},
    "is_weekend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Weekend Availabel ?"])},
    "is_weekdays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Weekdays Availabel ?"])},
    "start_time_holiday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Time Holiday"])},
    "start_time_weekend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Time Weekend"])},
    "start_time_weekdays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Time Weekdays"])},
    "end_time_holiday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Time Holiday"])},
    "end_time_weekend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Time Weekend"])},
    "end_time_weekdays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Time Weekdays"])},
    "placeholder": {
      "room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Room"])},
      "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Owner Room"])}
    }
  },
  "batas ulang v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["------------------------ Room Booking ---------------------------"])},
  "accessgrant": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access Grant"])},
    "titleV2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access Grant Detail"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Form"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Form"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Form"])},
    "editV2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add User"])},
    "addV2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit User"])},
    "detailV2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail User"])},
    "branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branch"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
    "event_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event Name"])},
    "event_start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event Start Date"])},
    "event_end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event End Date"])},
    "event_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event Date"])},
    "booking_start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking Start Date"])},
    "booking_end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking End Date"])},
    "booking_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking Date"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creator"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created At"])},
    "password_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
    "password_changed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password Change Date"])},
    "placeholder": {
      "branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branch..."])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name..."])},
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username..."])}
    },
    "button": {
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])}
    }
  },
  "batas ulang v3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["------------------------ Project ---------------------------"])},
  "project": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Project"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Project"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Project"])},
    "branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branch"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
    "background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Background"])},
    "objectives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objectives"])},
    "initiator1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initiator 1"])},
    "initiator2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initiator 2"])},
    "initiation_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initiation Date"])},
    "weight_sow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SOW Weight (%)"])},
    "weight_aspect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aspect Weight (%)"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created By"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created At"])},
    "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated By"])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated At"])},
    "vendor_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendor"])},
    "placeholder": {
      "branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Branch..."])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Name..."])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Type..."])},
      "background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Background..."])},
      "objectives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Objectives..."])},
      "initiator1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose..."])},
      "initiator2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose..."])},
      "initiation_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Initiation Date..."])},
      "weight_sow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert SOW Weight..."])},
      "weight_aspect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Aspect Weight..."])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Status ..."])}
    },
    "button": {
      "btnConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
      "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])}
    }
  },
  "logissue": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log Issue"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Log Issue"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Log Issue"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Log Issue"])},
    "project_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Name"])},
    "issue_type_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issue Type"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "initiator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initiator"])},
    "raised_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raised Date"])},
    "importance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importance"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created By"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created At"])},
    "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated By"])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated At"])},
    "log_issue_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "placeholder": {
      "project_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Project"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Description"])},
      "issue_type_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Type"])},
      "initiator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Initiator"])},
      "raised_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Date"])},
      "importance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Importance"])}
    },
    "button": {
      "btnConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
      "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
      "btnChangeRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Change Request"])}
    }
  },
  "logissuedetail": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Log Issue Detail"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log Issue Detail "])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Log Issue Detail"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Log Issue Detail"])},
    "project_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Name"])},
    "log_issue_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log Issue"])},
    "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solution"])},
    "pic1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PIC 1"])},
    "pic2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PIC 2"])},
    "deadline_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deadline Date"])},
    "resolve_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resolve Date"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remarks"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created By"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created At"])},
    "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated By"])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated At"])},
    "placeholder": {
      "project_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Project"])},
      "log_issue_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Log Issue"])},
      "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Solution"])},
      "pic1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Team Member 1"])},
      "pic2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Team Member 2"])},
      "deadline_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Deadline Date"])},
      "resolve_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Resolve Date"])},
      "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose State"])},
      "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Remarks"])}
    },
    "button": {
      "btnConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
      "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])}
    }
  },
  "batas ulang v4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["------------------------ Master ---------------------------"])},
  "idsetting": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Setting"])},
    "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digit Of Project Code: "])},
    "mom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digit Of MOM Code: "])},
    "change_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digit Of Change Request: "])},
    "blacklisting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approver for Blacklisting Vendor :"])},
    "whitelisting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approver for Whitelisting Vendor: "])},
    "placeholder": {
      "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Digit Of Project"])},
      "mom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Digit Of MOM"])},
      "change_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Digit Of Change Request"])},
      "blacklisting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert User ID"])},
      "whitelisting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert User ID"])}
    }
  },
  "activityformeeting": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sync to Room Booking System"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity types for meeting : "])},
    "placeholder": {
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Type"])}
    }
  },
  "documentexpiredreminder": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Expired Reminder"])},
    "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Days"])},
    "placeholder": {
      "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Days..."])}
    }
  },
  "projecttype": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Type"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Project Type"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Project Type"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Project Type"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created By"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created At"])},
    "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated By"])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated At"])},
    "placeholder": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Name..."])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Code..."])}
    },
    "button": {
      "btnConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
      "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])}
    }
  },
  "legaldocument": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal Document"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Legal Document"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Legal Document"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Legal Document"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "has_exp_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has Exp Date"])},
    "monitor_exp_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monitor Exp Date"])},
    "is_mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Mandatory"])},
    "upload_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload File"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created By"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created At"])},
    "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated By"])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated At"])},
    "placeholder": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Legal Document Name"])},
      "has_exp_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has Exp Date"])},
      "monitor_exp_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monitor Exp Date"])},
      "is_mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Mandatory"])},
      "upload_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload File"])}
    },
    "button": {
      "btnConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
      "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])}
    }
  },
  "vendortype": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendor Type"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Vendor Type"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Vendor Type"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Vendor Type"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created By"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created At"])},
    "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated By"])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated At"])},
    "placeholder": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Name..."])}
    },
    "button": {
      "btnConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
      "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])}
    }
  },
  "vendor": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendor"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Vendor"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Vendor"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Vendor"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nickname"])},
    "sync_with_erp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synced with ERP"])},
    "sync_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sync Date"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Province"])},
    "postal_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal Code"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "wa_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WhatsApp"])},
    "tax_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax Number"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
    "list_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listed Date"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created By"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created At"])},
    "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated By"])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated At"])},
    "leverage_level_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leverage Level"])},
    "placeholder": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Name"])},
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Nickname"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Address"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert City"])},
      "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Province"])},
      "postal_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Postal Code"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Phone Number"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Email Address"])},
      "wa_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Whatsapp Number"])},
      "tax_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Tax Number"])},
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Note"])},
      "leverage_level_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Leverage Level ..."])}
    },
    "button": {
      "btnConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
      "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])}
    }
  },
  "aspecttype": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aspect Type"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Aspect Type"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Aspect Type"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Aspect Type"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "aspect_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aspect"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created By"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created At"])},
    "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated By"])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated At"])},
    "placeholder": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Description"])},
      "aspect_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Aspect"])}
    },
    "button": {
      "btnConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
      "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])}
    }
  },
  "issuetype": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issue Type"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Issue Type"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Issue Type"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail issue Type"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "project_type_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Type"])},
    "aspect_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["issue"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created By"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created At"])},
    "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated By"])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated At"])},
    "placeholder": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Name..."])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Type..."])}
    },
    "button": {
      "btnConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
      "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])}
    }
  },
  "contactperson": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Person"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Contact Person"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Contact Person"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Contact Person"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "vendor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendor"])},
    "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
    "wa_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WhatsApp"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created By"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created At"])},
    "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated By"])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated At"])},
    "placeholder": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Name..."])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Email"])},
      "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Position"])},
      "wa_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Whatsapp Number"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Phone Number"])}
    },
    "button": {
      "btnConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
      "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])}
    }
  },
  "vendorlegaldoc": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendor Legal Doc"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Vendor Legal Doc"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Vendor Legal Doc"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Vendor Legal Doc"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendor"])},
    "document_legal_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["document_legal_id"])},
    "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remarks"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number"])},
    "issuance_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issuance Date"])},
    "exp_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exp Date"])},
    "issuance_institution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issuance Institution"])},
    "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File"])},
    "vendor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal Document"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created By"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created At"])},
    "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated By"])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated At"])},
    "placeholder": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Name..."])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Email"])},
      "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Position"])},
      "wa_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Whatsapp Number"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Phone Number"])},
      "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Remarks"])},
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Number"])},
      "issuance_institution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Institution"])}
    },
    "button": {
      "btnConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
      "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])}
    }
  },
  "vendorleveragelevel": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendor Leverage Level"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Vendor Leverage level"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Vendor Leverage level"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Vendor Leverage level"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created By"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created At"])},
    "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated By"])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated At"])},
    "placeholder": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Name..."])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Description..."])}
    },
    "button": {
      "btnConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
      "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])}
    }
  },
  "changerequest": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Request"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Change Request"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Change Request"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Change Request"])},
    "initiator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initiator"])},
    "project_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number"])},
    "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remarks"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
    "change_request_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Request Date"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Request"])},
    "impact_to_time_line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impact to Timeline"])},
    "impact_to_scope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impact to Scope"])},
    "impact_to_cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impact to Cost"])},
    "impact_to_quality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impact to Quality"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created By"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created At"])},
    "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated By"])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated At"])},
    "placeholder": {
      "initiator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Initiator"])},
      "project_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Project"])},
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Number"])},
      "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Remarks"])},
      "change_request_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Request Date"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Description"])},
      "impact_to_time_line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Impact to Timeline"])},
      "impact_to_scope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Impact to Scope"])},
      "impact_to_cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Impact to Cost"])},
      "impact_to_quality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Impact to Quality"])}
    },
    "button": {
      "btnConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
      "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])}
    }
  },
  "changerequestapp": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Request App"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Change Request App"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Change Request App"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Change Request App"])},
    "initiator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initiator"])},
    "project_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project"])},
    "change_request_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
    "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason"])},
    "approver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approver"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created By"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created At"])},
    "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated By"])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated At"])},
    "placeholder": {
      "initiator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Initiator"])},
      "project_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Project"])},
      "change_request_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Request"])},
      "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert State"])},
      "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Reason"])},
      "approver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Approver"])}
    },
    "button": {
      "btnConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
      "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])}
    }
  },
  "projectteamrole": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Team Role"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Project Team Role"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Project Team Role"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Project Team Role"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Role is"])},
    "used_in_procurement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Used In Procurement"])},
    "used_in_production": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Used In Production"])},
    "ist_third_party": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Third Party"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created By"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created At"])},
    "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated By"])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated At"])},
    "placeholder": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Name..."])},
      "flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Role..."])}
    },
    "button": {
      "btnConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
      "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])}
    }
  },
  "vendorstatus": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendor Status"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Vendor Status"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Vendor Status"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Vendor Status"])},
    "vendor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendor"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
    "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason"])},
    "user_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated By"])},
    "approved_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approved By"])},
    "approved_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approved Time"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created By"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created At"])},
    "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated By"])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated At"])},
    "placeholder": {
      "vendor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Vendor"])},
      "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose State "])},
      "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Reason"])},
      "user_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose User"])}
    },
    "button": {
      "btnConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
      "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])}
    }
  },
  "vendortypevendor": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendor Type Vendor"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Vendor Type Vendor"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Vendor Type Vendor"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Vendor Type Vendor"])},
    "vendor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendor"])},
    "vendor_type_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendor Type"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created By"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created At"])},
    "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated By"])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated At"])},
    "placeholder": {
      "vendor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Vendor..."])},
      "vendor_type_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Vendor Type..."])}
    },
    "button": {
      "btnConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
      "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])}
    }
  },
  "expectedscope": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scope of Work"])},
    "scopeofwork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scope of Work"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max Score"])},
    "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min Score"])},
    "importance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importance"])},
    "expected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Management Expected"])},
    "commitment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendor Commitment"])},
    "actual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actual"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Scope of Work"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Scope of Work"])},
    "placeholder": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Name..."])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Description..."])},
      "importance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Importance..."])},
      "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Max Score..."])},
      "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Min Score..."])}
    },
    "button": {
      "btnConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
      "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])}
    }
  },
  "timeline": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeline"])},
    "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version"])},
    "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creator"])},
    "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created Date"])},
    "planning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planning"])},
    "actual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actual"])},
    "reasonofchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason Of Change"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
    "copy_timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy Timeline"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Timeline"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Timeline"])},
    "placeholder": {
      "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Version..."])},
      "reasonofchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Reason Of Change..."])}
    },
    "button": {
      "btnConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
      "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])}
    }
  },
  "internalteam": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal Team"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
    "active_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Date"])},
    "inactive_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive Date"])},
    "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Internal Team"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Internal Team"])},
    "button": {
      "btnConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
      "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])}
    }
  },
  "planning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planning"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Planning"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Planning"])},
    "task_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task No."])},
    "task_parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent Task"])},
    "task_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task Name"])},
    "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Date"])},
    "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Date"])},
    "allow_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow Payment"])},
    "milestone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Milestone"])},
    "task_pct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task Pct (%)"])},
    "progress_pct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progress Pct (%)"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "name_parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name Parent"])},
    "start_end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start - End Date"])},
    "actual_start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actual Start Date"])},
    "actual_end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actual End Date"])},
    "actual_remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actual Remarks"])},
    "is_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
    "button": {
      "btnConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
      "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])}
    }
  },
  "paymentterm": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Term"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Payment Term"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Payment Term"])},
    "purchase_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase No"])},
    "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creator"])},
    "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created Date"])},
    "timeline_planing_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Timeline Planning"])},
    "task_start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeline Start"])},
    "task_end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeline End"])},
    "vendor_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendor name"])},
    "purchase_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Datee"])},
    "placeholder": {
      "timeline_planing_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Select Timeline Planning"])},
      "purchase_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Select Purchase No"])}
    },
    "button": {
      "btnConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
      "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])}
    }
  },
  "deliverable": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deliverable"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Deliverable"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Deliverable"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creator"])},
    "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created Date"])},
    "placeholder": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Name..."])}
    },
    "button": {
      "btnConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
      "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])}
    }
  },
  "milestonedoc": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Milestone Document"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Milestone Document"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Milestone Document"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creator"])},
    "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created Date"])},
    "placeholder": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert..."])}
    },
    "button": {
      "btnConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
      "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])}
    }
  },
  "actual": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actual"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Actual"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Actual"])},
    "task_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task No."])},
    "task_parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent Task"])},
    "task_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task Name"])},
    "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Date"])},
    "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Date"])},
    "button": {
      "btnConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
      "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])}
    }
  },
  "minuteofmeeting": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minute Of Meeting"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail M.O.M"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add M.O.M"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit M.O.M"])},
    "agenda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agenda"])},
    "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
    "start_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Time"])},
    "end_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Time"])},
    "venue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venue"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created By"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created At"])},
    "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated By"])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated At"])},
    "placeholder": {
      "agenda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert the Agenda..."])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert the Code..."])},
      "venue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert the Venue..."])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert the Description...."])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert the Status..."])},
      "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert the Reason..."])}
    }
  },
  "invitation": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitation"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "mom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["M.O.M"])},
    "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project"])},
    "send_invitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Invitation"])},
    "placeholder": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Email..."])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Name..."])}
    }
  },
  "attendance": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendance"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "mom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["M.O.M"])},
    "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project"])},
    "placeholder": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Email..."])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Name..."])}
    }
  },
  "result": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Result"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Result"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project"])},
    "mom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["M.O.M"])},
    "addResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Result"])},
    "placeholder": {
      "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the result..."])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the description..."])}
    }
  },
  "Rescheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rescheduled"])},
  "todo": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To Do"])},
    "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PIC"])},
    "pic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PIC"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Date"])},
    "deadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deadline"])},
    "complete_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete Date"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remarks"])},
    "mom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["M.O.M"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "addTodo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add To Do"])},
    "reTodo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rescheduled To Do"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
    "placeholder": {
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert the PIC..."])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert the Name..."])},
      "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a Status..."])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert the Description..."])},
      "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert the Remarks..."])}
    },
    "form": {
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PIC"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Date"])},
      "deadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deadline"])},
      "complete_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete Date"])},
      "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remarks"])}
    }
  },
  "pitchingAspect": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pitching Aspect "])},
    "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project"])},
    "aspectType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aspect"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Pitching Aspect"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Pitching Aspect"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created At"])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated At"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created By"])},
    "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated By"])},
    "placeholder": {
      "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Project"])},
      "aspectType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Aspect"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Description"])},
      "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Weight"])}
    },
    "button": {
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])}
    }
  },
  "pitchingAspectDetail": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pitching Aspect Detail"])},
    "pitchingAspect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pitching Aspect"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])},
    "aspect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aspect"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Pitching Aspect Detail"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Pitching Aspect Detail"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created At"])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated At"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created By"])},
    "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated By"])},
    "placeholder": {
      "pitchingAspect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Pitching Aspect"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Description"])},
      "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert score"])}
    },
    "button": {
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])}
    }
  },
  "projectVendor": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendor "])},
    "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project"])},
    "vendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendor"])},
    "vendortype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendor Type"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Vendor"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Vendor"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Vendor"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created At"])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated At"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created By"])},
    "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated By"])},
    "placeholder": {
      "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Project"])},
      "vendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert vendor"])},
      "vendortype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Vendor Type"])}
    },
    "button": {
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])}
    }
  },
  "projectVendorType": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendor type"])},
    "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project"])},
    "vendortype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendor Type"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Vendor Type"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Vendor Type"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Vendor Type"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created At"])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated At"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created By"])},
    "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated By"])},
    "placeholder": {
      "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Project"])},
      "vendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert vendor Type"])},
      "vendortype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Vendor Type"])}
    },
    "button": {
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])}
    }
  },
  "pitchingProject": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project  Pitching"])},
    "branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branch"])},
    "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project"])},
    "vendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendor"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number"])},
    "pitching_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pitching Date"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewer"])},
    "totalScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score Total"])},
    "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remarks"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
    "winner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is Winner"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Pitching Project"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Pitching Project"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created At"])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated At"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created By"])},
    "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated By"])},
    "placeholder": {
      "branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Branch"])},
      "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Project"])},
      "vendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert vendor"])},
      "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Remarks"])},
      "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Reviewer"])}
    },
    "button": {
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])}
    }
  },
  "pitchingProjectDetail": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pitching Project Detail"])},
    "expScopeDtl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expected Scope"])},
    "aspect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other Aspect"])},
    "aspectdtl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])},
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])},
    "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Answer"])},
    "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Remarks"])},
    "WeightedScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Point"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Pitching Project Detail"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Pitching Project Detail"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created At"])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated At"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created By"])},
    "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated By"])},
    "placeholder": {
      "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Project..."])},
      "vendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Vendor..."])},
      "expScopeDtl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Expected Scope..."])},
      "aspect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Other Aspect..."])},
      "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Score..."])},
      "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Answer..."])},
      "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Weight..."])},
      "WeightedScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Point..."])},
      "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Remarks..."])}
    },
    "button": {
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])}
    }
  },
  "projectEvaluation": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Evaluation"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Project Evaluation"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Project Evaluation"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
    "evaluation_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluation Date"])},
    "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creator"])},
    "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created Date"])},
    "button": {
      "btnConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
      "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])}
    }
  },
  "purchaseOrder": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase Order"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Purchase Order"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Purchase Order"])},
    "purchase_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase No"])},
    "purchase_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase Date"])},
    "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creator"])},
    "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created Date"])},
    "vendor_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendor name"])},
    "placeholder": {
      "purchase_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Purchase No..."])}
    },
    "button": {
      "btnConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
      "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
      "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])}
    }
  }
}